import React, { useState, Fragment, useContext } from 'react';
import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Form, Formik } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import { ApiService } from 'app/servicesTwo/ApiService';
import { useLocation } from 'react-router-dom';
import { updateInputValue } from '../../../utils/appHelpers';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { PermissionContext } from 'app/contexts/PermissionContext';
import { EllipsisTooltip } from 'app/components/EllipsisTooltip/EllipsisTooltip';
import formatDate from 'app/utils/formatDate';
import { es } from 'date-fns/locale';

const ModalChangeVolunteerActivityStatus = ({
  data,
  handleClose,
  getTeamVolunteer,
  getBookTeamsById
}) => {
  const { theme } = useJumboTheme();
  console.log(data);
  const location = useLocation();
  const lg = useMediaQuery(theme.breakpoints.down('lg'));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up('lg')]: {
            position: 'sticky',
            zIndex: 5,
            top: 96,
            minHeight: 'auto',
          },
          [theme.breakpoints.down('lg')]: {
            display: 'none',
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: 'flex-start',
        },
      },
    }),
    [theme]
  );

  const initialValues = {
    data_fim: data?.Atividade_Do_Voluntario?.data_fim ?? null,
    motivo_encerramento:
      data?.Atividade_Do_Voluntario?.motivo_encerramento ?? null,
  };

  console.log(initialValues);
  const Swal = useSwalWrapper();
  const toast = (variant, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: 'Fechar',
    });
  };

  const { hasPermission } = useContext(PermissionContext);

  const storedUserId = localStorage.getItem('storedUserId');

  const handleSubmit = async (values, { setSubmitting }) => {
    console.log(values);

    const activitySelected =
      data?.Atividade_Do_Voluntario?.atividade_do_voluntario_id;
    const isActive = data?.Atividade_Do_Voluntario?.status === 'ativo';

    if (!activitySelected) {
      console.error('Atividade não encontrada');
      return;
    }

    // Função auxiliar para atualizar a atividade
    const updateActivity = async () => {
      try {
        const allValues = {
          ...values,
          id_quem_atualizou: storedUserId,
        }
        await ApiService.put(
          `/atividades-do-voluntario/encerrar/${activitySelected}`,
          { values: allValues }
        );

        const message = isActive
          ? 'Atuação na atividade encerrada'
          : 'Atualizado com sucesso';

        toast('success', message);
        getTeamVolunteer();
        getBookTeamsById();
      } catch (error) {
        toast('error', 'Ocorreu um erro');
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('error', error.message);
        }
      }
    };

    if (isActive) {
      const result = await Swal.fire({
        title: 'Tem certeza que deseja encerrar?',
        text: 'Não será possível reverter a ação!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim!',
        cancelButtonText: 'Não!',
        reverseButtons: true,
      });

      if (result.value) {
        await updateActivity();
      }
    } else {
      await updateActivity();
    }
  };

  return (
    <JumboContentLayout layoutOptions={layoutOptions}>
      {lg && (
        <Stack spacing={2} direction={'row'} sx={{ mb: 3, mt: -2 }}></Stack>
      )}
      <Paper sx={{ p: '40px' }}>
        <Box
          display='flex'
          justifyContent='flex-end'
          alignItems='center'
          marginRight='1rem'
        >
          <Button size='small' variant='contained' onClick={handleClose}>
            Fechar
          </Button>
        </Box>
        <Typography
          variant={'h1'}
          style={{ textAlign: 'center', marginBottom: 30 }}
        >
          Atualização de Status
        </Typography>
        <Fragment>
          <Formik
            initialValues={initialValues}
            // validationSchema={validationSchema}
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={handleSubmit}
          >
            {({ values, isSubmitting, handleChange }) => (
              <Form style={{ width: '100%' }} noValidate autoComplete='off'>
                <Grid container spacing={3} alignContent={'center'}>
                  <Grid item xs={12}>
                    <Box display={'flex'} gap={1} alignItems={'center'}>
                      <Typography variant='h4' fontWeight={'300'}>
                        Aluno selecionado:
                      </Typography>

                      <Typography
                        variant='h4'
                        textTransform={'capitalize'}
                        fontWeight={'bold'}
                      >
                        {data?.nome}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item lg={3} md={3} xs={2}>
                    <FormControl fullWidth>
                      <JumboTextField
                        fullWidth
                        name='data_fim'
                        label='Saída'
                        type='date'
                        value={values.data_fim}
                        sx={{ background: '#ffffff' }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} md={3} xs={2}>
                    <FormControl fullWidth>
                      <JumboTextField
                        fullWidth
                        name='motivo_encerramento'
                        label='Motivo'
                        value={values.motivo_encerramento}
                        sx={{ background: '#ffffff' }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <LoadingButton
                      color='success'
                      type='submit'
                      variant='contained'
                      size='large'
                      sx={{ maxWidth: { md: '300px', mt: '1rem' } }}
                      loading={isSubmitting}
                      disabled={
                        !hasPermission('Alocação de Colaboradores', 'editar') ||
                        !values.data_fim ||
                        !values.motivo_encerramento
                      }
                    >
                      {data?.Atividade_Do_Voluntario?.status === 'ativo'
                        ? 'Encerrar Voluntariado'
                        : 'Atualizar'}
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Fragment>
      </Paper>
    </JumboContentLayout>
  );
};

export default ModalChangeVolunteerActivityStatus;
