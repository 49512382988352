import React, { useCallback, useContext, useState } from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import {
  Accordion,
  Card,
  Tooltip,
  Typography,
  Grid,
  Modal,
  Backdrop,
  Fade,
  Box,
} from '@mui/material';
import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { ApiService } from 'app/servicesTwo/ApiService';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import Zoom from '@mui/material/Zoom';
import { PermissionContext } from 'app/contexts/PermissionContext';
import { LinkStyle } from '../users/UserItem';
import {
  useLocation,
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  MemoryRouter,
} from 'react-router-dom';
import { EllipsisTooltip } from 'app/components/EllipsisTooltip/EllipsisTooltip';
import moment from 'moment';
import Div from '@jumbo/shared/Div';
import ModalChangeVolunteerActivityStatus from './ModalChangeVolunteerActivityStatus';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80em',
  p: 4,
};

const VolunteerTeamListItem = ({
  item,
  setVolunteersTeam,
  volunteersTeam,
  getBookTeamsById,
  getTeamVolunteer,
}) => {
  const location = useLocation();
  const { hasPermission } = useContext(PermissionContext);

  const [open, setOpen] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);

  const handleCloseStatusModal = () => {
    setOpenStatusModal(false);
    Swal.close();
  };

  const Swal = useSwalWrapper();
  const toast = (variant, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: 'Fechar',
    });
  };

  const modalAlert = (curso_historico_id) => {
    Swal.fire({
      title: 'Tem certeza que deseja apagar?',
      text: 'Não será póssível reverter a ação!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        removeStudentFromClass(curso_historico_id);
      }
    });
  };

  function removeById(arr, id) {
    console.log('arr', arr);
    console.log('id', id);
    const objWithIdIndex = arr.findIndex((obj) => obj.equipe_id === id);

    if (objWithIdIndex > -1) {
      arr.splice(objWithIdIndex, 1);
    }

    return arr;
  }

  const removeStudentFromClass = useCallback(
    async (equipe_id) => {
      try {
        ApiService.delete(`/equipes/voluntario/${equipe_id}`)
          .then((response) => {
            toast('success', 'Removido com sucesso');

            const updatedStudentsArray = removeById(volunteersTeam, equipe_id);
            setVolunteersTeam(updatedStudentsArray);
            getBookTeamsById();
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log('error', error.message);
            }
          });
      } catch (err) {
        console.log(err);
        toast('error', 'Ocorreu um erro');
      }
    },
    [volunteersTeam, setVolunteersTeam]
  );

  const formatDate = (date) => {
    if (!date) return '-';
    return moment(date).format('DD/MM/YYYY');
  };

  return (
    <Card sx={{ mb: 1 }}>
      <Accordion square sx={{ borderRadius: 2 }}>
        <AccordionSummary
          aria-controls='panel1bh-content'
          id='panel1bh-header'
          sx={{
            px: 3,
            flexDirection: 'row-reverse',

            '& .MuiAccordionSummary-content': {
              alignItems: 'center',

              '&.Mui-expanded': {
                margin: '12px 0',
              },
            },
            '.MuiAccordionSummary-expandIconWrapper': {
              borderRadius: 1,
              border: 1,
              color: 'text.secondary',
              borderColor: 'divider',
              transform: 'none',
              height: 28,
              width: 28,
              alignItems: 'center',
              justifyContent: 'center',
              mr: 1,

              '&.Mui-expanded': {
                transform: 'none',
                color: 'primary.main',
                borderColor: 'primary.main',
              },

              '& svg': {
                fontSize: '1.25rem',
              },
            },
          }}
        >
          <Grid container spacing={1} alignContent={'center'}>
            <Grid item lg={4} md={4} xs={6}>
              <Typography
                fontSize={'12px'}
                variant={'h6'}
                color={'text.secondary'}
                mb={0.25}
              >
                Nome
              </Typography>
              <EllipsisTooltip
                text={item?.Atividade_Do_Voluntario?.Voluntario?.User?.nome}
              />
            </Grid>

            <Grid
              item
              lg={2}
              md={3}
              xs={2}
              sx={{ display: { xs: 'none', md: 'none', lg: 'block' } }}
            >
              <Typography
                fontSize={'12px'}
                variant={'h6'}
                color={'text.secondary'}
                mb={0.25}
              >
                Celular
              </Typography>
              <EllipsisTooltip
                text={item?.Atividade_Do_Voluntario?.Voluntario?.User?.celular}
              />
            </Grid>
            <Grid
              item
              lg={2}
              md={3}
              xs={2}
              sx={{ display: { xs: 'none', md: 'none', lg: 'block' } }}
            >
              <Typography
                fontSize={'12px'}
                variant={'h6'}
                color={'text.secondary'}
                mb={0.25}
              >
                Atividade
              </Typography>
              <EllipsisTooltip
                text={
                  item?.Atividade_Do_Voluntario?.Atividades_Voluntario?.nome
                }
              />
            </Grid>
            <Grid
              item
              lg={2}
              md={3}
              xs={2}
              sx={{ display: { xs: 'none', md: 'none', lg: 'block' } }}
            >
              <Typography
                fontSize={'12px'}
                variant={'h6'}
                color={'text.secondary'}
                mb={0.25}
              >
                Admissão
              </Typography>
              <EllipsisTooltip
                text={formatDate(item?.Atividade_Do_Voluntario?.data_inicio)}
              />
            </Grid>
            <Grid item lg={2} md={2} xs={4}>
              <Box display={'flex'} alignItems={'center'} gap={1}>
                <Tooltip title='Status' TransitionComponent={Zoom} arrow>
                  <Button
                    sx={{ width: 1 }}
                    disableElevation
                    variant={'contained'}
                    size={'small'}
                    color={'primary'}
                    onClick={() => setOpenStatusModal(true)}
                  >
                    {item?.Atividade_Do_Voluntario?.status}
                  </Button>
                </Tooltip>

                <Tooltip title='Visualizar' TransitionComponent={Zoom} arrow>
                  <Button
                    sx={{ minWidth: '2rem' }}
                    variant={'contained'}
                    size={'small'}
                    component={RouterLink}
                    to={`/app/editar-aluno/${item?.Atividade_Do_Voluntario?.Voluntario?.User?.pessoa_id}`}
                    state={{ backUrl: location.pathname, disable: true }}
                  >
                    <VisibilityIcon fontSize='small' />
                  </Button>
                </Tooltip>

                <Tooltip title='Excluir' TransitionComponent={Zoom} arrow>
                  <Button
                    sx={{ minWidth: '2rem' }}
                    disableElevation
                    variant={'contained'}
                    size={'small'}
                    color={'secondary'}
                    disabled={
                      location?.pathname.includes('edit')
                        ? true
                        : !hasPermission(
                            'Inclusão de Alunos na Turma',
                            'deletar'
                          )
                    }
                    onClick={() => modalAlert(item.equipe_id)}
                  >
                    <DeleteIcon fontSize='small' />
                  </Button>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        </AccordionSummary>
      </Accordion>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={openStatusModal}
        onClose={handleCloseStatusModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openStatusModal}>
          <Div sx={style}>
            <ModalChangeVolunteerActivityStatus
              data={item}
              curso_historico_id={item.curso_historico_id}
              handleClose={handleCloseStatusModal}
              getTeamVolunteer={getTeamVolunteer}
              getBookTeamsById={getBookTeamsById}
            />
          </Div>
        </Fade>
      </Modal>
    </Card>
  );
};
/* Todo item prop define */
export default VolunteerTeamListItem;
